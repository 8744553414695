import { defineStore } from 'pinia';
import { useRuntimeConfig } from '#app';
import { getMessaging, getToken } from 'firebase/messaging';
import { useSessionStore } from '~~/stores/SessionStore';
const { checkStatusCode } = useCheckRequestStatusCode();

export const useAuthStore = defineStore('auth', {
  state: () => ({
    loading: false,
    coachesFirebase: [],
    coachesFromRealTimeDb: [],
    updatedCoachesFromFirebase: [],
    responseData: null,
    verifiedMessage: null,
    acceptedTerms: true,
    serverErrors: [],
    statuses: {},
    checkResponse: false,
    signUp: {
      id: '',
      countries: null,
      nationalities: null,
    },
    signInData: {
      id: '',
    },
    auth: {
      user: null,
      isLoggedIn: false,
      headers: {
        accept: '*/*',
      },
    },
    sessionTimer: null,
    sessionToken: null,
    channelName: null,
    pushNotificationData: null,
    pushNotificationResponse: null,
    isGrantedAllowNotifications: false,
    fcmToken: null,
    useWithoutNotification: null,
  }),
  getters: {
    fullName(state) {
      if (state.auth.user) {
        return state.auth.user.firstName + ' ' + state.auth.user.lastName;
      }
    },
    countriesWithAllOption(state) {
      if (state.signUp.countries) {
        const allOption = { id: '', name: 'الكل' };
        return [allOption, ...state.signUp.countries];
      }
    },
    coachUrl() {
      const appEnv = useRuntimeConfig().public.appEnv;
      switch (appEnv) {
        case 'development':
          return 'https://testing-mentor.calwe.com';
        case 'testing':
          return 'https://testing-mentor.calwe.com';
        case 'staging':
          return 'https://staging-mentor.calwe.com';
        case 'production':
          return 'https://mentor.calwe.com';
        default:
          return 'https://testing-mentor.calwe.com';
      }
    },
  },
  actions: {
    async registration(payload) {
      const formData = new FormData();
      for (const key in payload) {
        if (key !== 'countryId' && key !== 'nationalId') {
          formData.append(key, payload[key]);
        }
      }
      formData.append('countryId', payload.countryId);
      formData.append('nationalId', payload.nationalId);

      this.loading = true;
      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/register`,
        {
          method: 'POST',
          body: formData,
        }
      );

      this.responseData = response.value || error.value.data;
      setTimeout(() => {
        this.responseData = null;
        this.loading = false;
      }, 3000);

      if (!error.value) {
        navigateTo('/auth/signin');
      } else {
        this.loading = false;
        this.serverErrors = error.value.data.errors;
        return false;
      }

      this.loading = false;
    },
    async signIn(payload) {

      const sessionStore = useSessionStore();
      const formData = new FormData();
      this.loading = true;
      const messaging = getMessaging();
      
        await Notification.requestPermission(async (permission) => {
          if (permission === 'granted') {
            this.isGrantedAllowNotifications = true;
          } else {
            this.isGrantedAllowNotifications = false;
          }
        });

           if (this.isGrantedAllowNotifications || this.fcmToken) {
        const serviceWorkerRegistration = await navigator.serviceWorker
          .register('/firebase-messaging-sw.js', { scope: '/' })
          .catch((err) => {
            return console.log('[Service Worker] Registration Error:', err);
          });
        await navigator.serviceWorker.ready; // Here's the waiting

        // eslint-disable-next-line no-unused-vars
        const subscription = await serviceWorkerRegistration.pushManager
          .subscribe({
            userVisibleOnly: true,
            applicationServerKey: useRuntimeConfig().public.fcmPublicVapIdKey,
          })
          .catch((err) => {
            return console.log('[Web Push] Registration Error:', err);
          });
        console.log('[Web Push] Registered');
        if(this.fcmToken) {
          formData.append('fcmToken', this.fcmToken);
        }
        else{
          await getToken(messaging, {
            vapidKey: useRuntimeConfig().public.fcmPublicVapIdKey,
            serviceWorkerRegistration: serviceWorkerRegistration,
          }).then((token) => {
            if (token) {
              formData.append('fcmToken', token);
            }
          });
        }
      }

      const generateRandomId = Math.floor(Math.random() * 899999 + 100000);
      formData.append('deviceId', generateRandomId);

      for (const key in payload) {
        if (payload[key]) {
          formData.append(key, payload[key]);
        }
      }
      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/login`,

        {
          method: 'POST',
          body: formData,
        }
      );
      this.loading = false;
      this.responseData = response.value || error.value.data;
      this.statuses = response.value;
      setTimeout(() => {
        this.responseData = null;
      }, 3000);

      if (!error.value) {
        if (response.value.statusCode === 301) {
          this.statuses = response.value;
        } else {
          const { setValue } = useLocalStorage();

          setValue('token', response.value.data.token); // Store Token in LocalStorage
          this.setHeaders(response.value.data.token);
          if (!sessionStore.currentSessionId) {
            await sessionStore.getCurrentSessionId();
          }
          if (!this.acceptedTerms) {
            this.acceptedTerms = true;
          }
          await this.getProfile();
          await setTimeout(() => {
            navigateTo('/');
          }, 4000);
        }
      } else {
        this.serverErrors = error.value.data.errors;
        if (error.value.data.statusCode === 406) {
          this.acceptedTerms = false;
        }
      }
    },
    async getProfile() {
      const { checkStatusCode } = useCheckRequestStatusCode();

      if (this.auth.headers.Authorization) {
        const { getValue } = useLocalStorage();

        const route = useRoute();
        this.loading = true;
        const waitingForRespond = getValue('waitingForRespond');
        const { data: response, error } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}/profile`,
          {
            method: 'GET',
            headers: this.auth.headers,
          }
        );
        this.loading = false;
        if (!error.value) {
          this.auth.isLoggedIn = true;
          this.auth.user = response.value.data;
          if (!this.sessionTimer) {
            this.sessionTimer = response.value.data.sessionTimer;
          }

          if (
            waitingForRespond &&
            !response.value.data.sessionTimer &&
            (!this.pushNotificationData ||
              (this.pushNotificationData &&
                this.pushNotificationData.status === 'pending'))
          ) {
            this.getDirectSessionRequestNotificationData();
          }

          if (route.name === 'auth-signin') {
            return navigateTo({ path: '/' });
          }
        } else {
          if (error.value.data && error.value.data.status_code) {
            checkStatusCode(error.value.data.status_code);
          }
        }
      }
    },
    async signOut() {
      const sessionStore = useSessionStore();
      const { checkStatusCode } = useCheckRequestStatusCode();
      if (this.auth.headers.Authorization) {
        const { error } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}/logout`,

          {
            method: 'POST',
            headers: this.auth.headers,
          }
        );
        if (!error.value) {
          const { removeValue } = useLocalStorage();
          removeValue('token');
          sessionStore.currentSessionId = null;
          this.auth.isLoggedIn = false;
          this.auth.headers = {};
          this.sessionTimer = null;
          setTimeout(() => {
            navigateTo({ path: '/' });
          }, 2000);
        } else {
          checkStatusCode(error.value.data.status_code);
        }
      }
    },
    async fetchCountriesList() {
      this.loading = true;
      // eslint-disable-next-line no-undef
      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/countries`,
        {
          method: 'GET',
          params: {
            list: true,
          },
        }
      );
      this.loading = false;
      if (!error.value) {
        this.signUp.countries = response.value.data.countries;
      } else {
        this.serverErrors = error.value.data.errors;
      }
    }, 
    async fetchNationalitiesList() {
      this.loading = true;
      // eslint-disable-next-line no-undef
      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/countries`,
        {
          method: 'GET',
          params: {
            nationality_list: true,
          },
        }
      );
      this.loading = false;
      if (!error.value) {
        this.signUp.nationalities = response.value.data.countries;
      } else {
        this.serverErrors = error.value.data.errors;
      }
    },
    async checkActivatedAccount(payload) {
      this.loading = true;
      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/activate-account`,
        {
          method: 'POST',
          query: {
            token: payload.token,
            email: payload.email,
          },
        }
      );

      this.loading = false;
      if (!error.value) {
        this.responseData = response.value;
      } else {
        this.responseData = error.value.data;
        this.serverErrors = error.value.data.errors;
      }
    },
    async forgetPassword(payload) {
      const formData = new FormData();
      for (const key in payload) {
        formData.append(key, payload[key]);
      }

      this.loading = true;
      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/forgot-password`,
        {
          method: 'POST',
          body: formData,
        }
      );
      this.loading = false;
      if (!error.value) {
        this.responseData = response.value;

        return true;
      } else {
        this.serverErrors = error.value.data.errors;

        return false;
      }
    },
    async resetPassword(payload) {
      const formData = new FormData();
      for (const key in payload.data) {
        formData.append(key, payload.data[key]);
      }

      this.loading = true;
      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/reset-password/${payload.token}`,
        {
          method: 'POST',
          body: formData,
        }
      );
      this.loading = false;
      if (!error.value) {
        this.responseData = response.value;
        setTimeout(() => {
          this.responseData = null;
          navigateTo({ path: '/auth/signin' });
        }, 3000);
        return true;
      } else {
        this.serverErrors = error.value.data;
        this.responseData = error.value.data;

        setTimeout(() => {
          this.responseData = null;
        }, 3000);
        return false;
      }
    },
    setHeaders(token) {
      this.auth.headers.Authorization = `Bearer ${token}`;
    },
    async sendPhoneVerified(payload) {
      this.loading = true;
      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/phone-verification`,
        {
          method: 'POST',
          params: {
            phoneVerifiedCode: payload,
          },
        }
      );

      this.verifiedMessage = response.value || error.value.data;
      setTimeout(() => {
        this.responseData = null;
      }, 3000);

      if (!error.value) {
        this.checkResponse = true;
      }

      this.loading = false;
    },
    async resendPhoneVerified(payload) {
      const formData = new FormData();
      for (const key in payload) {
        formData.append(key, payload[key]);
      }
      this.loading = true;
      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/resend-verification-phone-code`,
        {
          method: 'POST',
          body: formData,
        }
      );

      this.responseData = response.value || error.value.data;
      setTimeout(() => {
        this.responseData = null;
      }, 3000);

      this.loading = false;
    },
    async changePhoneNumber(payload) {
      const formData = new FormData();
      for (const key in payload) {
        formData.append(key, payload[key]);
      }
      this.loading = true;
      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/change-phone-number`,
        {
          method: 'POST',
          body: formData,
        }
      );

      this.responseData = response.value || error.value.data;
      this.statuses = response.value;
      setTimeout(() => {
        this.responseData = null;
      }, 3000);

      this.loading = false;
    },
    async sendCode(payload) {
      const formData = new FormData();
      for (const key in payload) {
        formData.append(key, payload[key]);
      }
      this.loading = true;
      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/send-code`,
        {
          method: 'POST',
          body: formData,
        }
      );

      this.responseData = response.value || error.value.data;
      setTimeout(() => {
        this.responseData = null;
      }, 3000);

      this.loading = false;
    },
    async getDirectSessionRequestNotificationData() {
      const { checkStatusCode } = useCheckRequestStatusCode();
      if (this.auth.headers.Authorization) {
        const { getValue, removeValue } = useLocalStorage();
        const waitingForRespond = getValue('waitingForRespond');
        const { data: response, error } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}/session-request`,
          {
            method: 'GET',
            headers: this.auth.headers,
          }
        );
        if (!error.value) {
          if (
            waitingForRespond &&
            (response.value.data.status === 'rejected' ||
              response.value.data.status === 'accepted' || response.value.data.status === 'paid') &&
            (!this.pushNotificationData ||
              (this.pushNotificationData &&
                this.pushNotificationData.status !== 'accepted' &&
                this.pushNotificationData.status !== 'rejected'))
          ) {
            this.sessionTimer = null;
            removeValue('waitingForRespond');
            this.pushNotificationData = response.value.data;
          }
        } else {
          checkStatusCode(error.value.data.status_code);
        }
      }
    },
    async resendActivationEmail(payload) {
      const formData = new FormData();
      formData.append('email', payload);
      this.loading = true;
      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/resend-activation-email`,
        {
          method: 'POST',
          body: formData,
        }
      );
      this.loading = false;

      if (!error.value) {
        this.responseData = response.value;
        return response.value;
      } else {
        this.responseData = error.value.data;
        checkStatusCode(error.value.data.status_code);

        this.serverErrors = error.value.data.errors;
      }
    },
    async loginByGoogleCallBack(payload) {
      
      const sessionStore = useSessionStore();
      const formData = new FormData();
      this.loading = true;
      const messaging = getMessaging();
      if(this.fcmToken == null){
        await Notification.requestPermission(async (permission) => {
          if (permission === 'granted') {
            this.isGrantedAllowNotifications = true;
          } else {
            this.isGrantedAllowNotifications = false;
          }
        });

        if (this.isGrantedAllowNotifications) {

          const serviceWorkerRegistration = await navigator.serviceWorker
            .register('/firebase-messaging-sw.js', { scope: '/' })
            .catch((err) => {
              return console.log('[Service Worker] Registration Error:', err);
            });
          await navigator.serviceWorker.ready; // Here's the waiting

          // eslint-disable-next-line no-unused-vars
          const subscription = await serviceWorkerRegistration.pushManager
            .subscribe({
              userVisibleOnly: true,
              applicationServerKey: useRuntimeConfig().public.fcmPublicVapIdKey,
            })
            .catch((err) => {
              return console.log('[Web Push] Registration Error:', err);
            });
          console.log('[Web Push] Registered');
            await getToken(messaging, {
              vapidKey: useRuntimeConfig().public.fcmPublicVapIdKey,
              serviceWorkerRegistration: serviceWorkerRegistration,
            }).then((token) => {
              if (token) {
                formData.append('fcmToken', token);
              }
            });
        }

      }
    else {
      formData.append('fcmToken', this.fcmToken);
      this.isGrantedAllowNotifications = true;
    }
      const generateRandomId = Math.floor(Math.random() * 899999 + 100000);
      formData.append('deviceId', generateRandomId);

      formData.append('google_id', payload);
      this.loading = true;
      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/auth/google/callback`,
        {
          method: 'POST',
          body: formData,
        }
      );

      this.loading = false;
      this.responseData = response.value || error.value.data;
      this.statuses = response.value;
      setTimeout(() => {
        this.responseData = null;
      }, 3000);

      if (!error.value) {
        const { setValue } = useLocalStorage();

          setValue('token', response.value.data.token); // Store Token in LocalStorage
          this.setHeaders(response.value.data.token);
          if (!sessionStore.currentSessionId) {
            await sessionStore.getCurrentSessionId();
          }
          if (!this.acceptedTerms) {
            this.acceptedTerms = true;
          }
          await this.getProfile();
          setTimeout(() => {
            navigateTo('/');
          }, 4000);

        this.responseData = response.value;
      } else {
        this.responseData = error.value.data;
        checkStatusCode(error.value.data.status_code);

        this.serverErrors = error.value.data.errors;
      }
    },
    async subscribeToEmail(payload) {
      const formData = new FormData();
      formData.append('email', payload);
      this.loading = true;
      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/subscribe-to-the-newsletter`,
        {
          method: 'POST',
          body: formData,
        }
      );
      this.loading = false;

      if (!error.value) {
        this.responseData = response.value;

        setTimeout(() => {
          this.responseData = null;
        }, 3000);
        
        return response.value;
      } else {
        this.responseData = error.value.data;
        checkStatusCode(error.value.data.status_code);

        this.serverErrors = error.value.data.errors;
      }
    },
  },
});
