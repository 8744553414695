import { defineStore } from 'pinia';
import { useRuntimeConfig } from '#app';
import { useAuthStore } from '@/stores/AuthStore';
import { useCoachStore } from './CoachStore';
const { checkStatusCode } = useCheckRequestStatusCode();

export const useSessionStore = defineStore('sessions', {
  state: () => ({
    btnLoading: false,
    loading: false,
    responseData: null,
    previousSessions: [],
    nextSessions: [],
    serverErrors: [],
    sessions: null,
    session: null,
    listCancelReasons: [],
    sessionStarted: false,
    gussetName: null,
    gussetAvatar: null,
    currentSessionId: null,
    duration: null,
    elapsedTime: null,
    sessionNumber: null,
    sessionDate: null,
    currentSessionCategory: null,
    needsRating: false,
    filter: null,
    sessionChats: null,
    sessionRecord: {},
    sendedFileUrl: null,
    records: null,
  }),
  actions: {
    async fetch(payload) {
      this.loading = true;
      this.filter = payload;
      const authStore = useAuthStore();

      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/sessions?page=${payload.page}`,
        {
          method: 'GET',
          headers: authStore.auth.headers,
          params: {
            type: payload.toggle,
            search_text: payload.searchText,
          },
        }
      );

      this.loading = false;
      if (!error.value) {
        this.sessions = response.value.data;
      } else {
        this.responseData = error.value;
      }
    },
    async fetchCancelReasons() {
      this.loading = true;
      const authStore = useAuthStore();

      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/cancel-reasons`,
        {
          method: 'GET',
          headers: authStore.auth.headers,
          params: {
            list: true,
          },
        }
      );

      this.loading = false;
      if (!error.value) {
        this.listCancelReasons = response.value.data;
      } else {
        this.responseData = error.value;
      }
    },
    async store(payload) {
      const formData = new FormData();
      const authStore = useAuthStore();
      const coachStore = useCoachStore();

      for (const key in payload) {
        // if(key == 'freeSession' && payload[key]) {
        //   formData.append(key, payload[key]);
        // }
        if (payload[key]) {
          formData.append(key, payload[key]);
        }
      }
      this.btnLoading = true;

      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/sessions`,
        {
          method: 'POST',
          headers: authStore.auth.headers,
          body: formData,
        }
      );
      if (!coachStore.coach.orientationCoach) {
        this.btnLoading = false;
      }

      if (
        (response.value && !coachStore.coach.orientationCoach) ||
        (error.value && !error.value.data.errors)
      ) {
        this.responseData = response.value || error.value.data;

        setTimeout(() => {
          this.responseData = null;
          this.btnLoading = false;
        }, 3000);
      }

      if (!error.value) {
        if (response.value.data.sessionTimer) {
          const { setValue, removeValue } = useLocalStorage();
          setValue('waitingForRespond', true);
          removeValue('order_id');
          if (!authStore.sessionTimer) {
            authStore.sessionTimer = response.value.data.sessionTimer;
          }
        }

        return true;
      } else {
        this.btnLoading = false;

        if (error.value.data.errors) {
          this.responseData = error.value.data;
          setTimeout(() => {
            this.responseData = null;
          }, 3000);
          this.serverErrors = error.value.data.errors;
        }
        checkStatusCode(error.value.data.status_code);

        return false;
      }
    },
    async startSession(uid) {
      const authStore = useAuthStore();
      this.loading = true;

      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/sessions/${
          this.currentSessionId
        }/start-call?uid=${uid}`,
        {
          method: 'GET',
          headers: authStore.auth.headers,
        }
      );

      this.loading = false;
      if (!error.value) {
        this.sessionStarted = true;
        const authStore = useAuthStore();
        const { setValue } = useLocalStorage();
        if (!authStore.sessionTimer) {
          authStore.sessionTimer = response.value.data.sessionTimer;
        }
        this.gussetName =
          response.value.data.firstName + ' ' + response.value.data.lastName;
        this.gussetAvatar = response.value.data.avatar;
        this.sessionNumber = response.value.data.number;
        this.sessionDate = response.value.data.date;
        authStore.rtmToken = response.value.data.chatToken;
        authStore.sessionToken = response.value.data.token;
        authStore.channelName = response.value.data.channelName;
        this.duration = response.value.data.duration;
        this.elapsedTime = response.value.data.elapsedTime;
        this.currentSessionCategory = response.value.data.categoryName;
        this.sessionChats = response.value.data.sessionChats;

        setValue('sessionToken', response.value.data.token);
        setValue('channelName', response.value.data.channelName);
        setValue('rtmToken', response.value.data.chatToken);
      }
    },
    async getCurrentSessionId() {
      const authStore = useAuthStore();
      const { getValue } = useLocalStorage();

      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/get-current-session`,
        {
          method: 'GET',
          headers: authStore.auth.headers,
        }
      );
      if (!error.value) {
        const route = useRoute();
        if (response.value.data.sessionId) {
          this.gussetName =
            response.value.data.firstName + ' ' + response.value.data.lastName;
          this.sessionNumber = response.value.data.number;
          this.sessionDate = response.value.data.date;
          this.needsRating = response.value.data.needsRating;
          this.currentSessionId = response.value.data.sessionId;
          authStore.sessionTimer = null;
          if (route.name !== 'room' && !response.value.data.needsRating && !getValue('leaveTemporarily')) {
            navigateTo('/room');
          }
        } else {
          if (route.name === 'room') {
            navigateTo('/sessions');
          }
        }
        return true;
      } else {
        if (error.value.data.errors) {
          this.serverErrors = error.value.data.errors;
        }
        checkStatusCode(error.value.data.status_code);
        return false;
      }
    },
    async cancelSession(payload) {
      const authStore = useAuthStore();
      const formData = new FormData();
      formData.append('_method', 'patch');
      for (const key in payload) {
        if (key !== 'sessiontId') {
          formData.append(key, payload[key]);
        }
      }
      this.btnLoading = true;

      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/sessions/${payload.sessiontId}`,
        {
          method: 'POST',
          headers: authStore.auth.headers,
          body: formData,
        }
      );

      if (response.value || !error.value.data.errors) {
        this.responseData = response.value || error.value.data;

        setTimeout(() => {
          this.responseData = null;
          this.btnLoading = false;
        }, 3000);
      }

      if (!error.value) {
        return true;
      } else {
        if (error.value.data.errors) {
          this.serverErrors = error.value.data.errors;
        }
        return false;
      }
    },
    async getSession(sessionId) {
      this.loading = true;
      const authStore = useAuthStore();
      this.responseData = null;
      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/sessions/${sessionId}`,
        {
          method: 'GET',
          headers: authStore.auth.headers,
        }
      );

      this.loading = false;
      if (!error.value) {
        this.session = response.value.data;
      } else {
        this.session = null;
        navigateTo('/sessions');
        this.responseData = error.value.data;
      }
    },
    async rateSession(payload) {
      const formData = new FormData();
      const authStore = useAuthStore();
      for (const key in payload) {
        if (key == 'rate' || key == 'comment') {
          formData.append(key, payload[key]);
        } else {
          formData.append(`rating_details[${key}]`, payload[key]);
        }
      }
      this.btnLoading = true;

      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/sessions/${
          this.currentSessionId
        }/rate`,
        {
          method: 'POST',
          headers: authStore.auth.headers,
          body: formData,
        }
      );

      if (response.value || !error.value.data.errors) {
        this.responseData = response.value || error.value.data;

        setTimeout(() => {
          this.responseData = null;
          this.btnLoading = false;
        }, 3000);
      }

      if (!error.value) {
        this.needsRating = false;
        return true;
      } else {
        if (error.value.data.errors) {
          this.serverErrors = error.value.data.errors;
        }
        checkStatusCode(error.value.data.status_code);

        return false;
      }
    },
    async sendMessage(payload) {
      const formData = new FormData();
      const authStore = useAuthStore();
      if (payload.type) {
        formData.append('message', payload.file);
        formData.append('messageType', payload.type);
      } else {
        formData.append('message', payload);
        formData.append('messageType', 'text');
      }
      this.btnLoading = true;
      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/sessions/${
          this.currentSessionId
        }/session-chat`,
        {
          method: 'POST',
          headers: authStore.auth.headers,
          body: formData,
        }
      );
      this.btnLoading = false;

      if (!error.value) {
        this.sendedFileUrl = response.value.data.message;
        this.needsRating = false;

        return true;
      } else {
        if (error.value.data.errors) {
          this.serverErrors = error.value.data.errors;
        }
        checkStatusCode(error.value.data.status_code);

        return false;
      }
    },
    async startRecording() {
      const authStore = useAuthStore();
      const { error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/sessions/${
          this.currentSessionId
        }/start-recording`,
        {
          method: 'POST',
          headers: authStore.auth.headers,
        }
      );

      if (!error.value) {
        return true;
      } else {
        return false;
      }
    },
    async endSession(endConfirm) {
      const authStore = useAuthStore();
      const formData = new FormData();
      formData.append(endConfirm, true);

      const { error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/sessions/${
          this.currentSessionId
        }/end-session`,
        {
          method: 'POST',
          headers: authStore.auth.headers,
          body: formData,
        }
      );

      if (!error.value) {
        return true;
      } else {
        return false;
      }
    },
    async getSessionRecord() {
      const authStore = useAuthStore();

      this.loading = true;
      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/sessions/${
          this.session.id
        }/stream-record`,
        {
          method: 'Get',
          headers: authStore.auth.headers,
        }
      );
      this.loading = false;
      if (!error.value) {
        this.sessionRecord = response.value;
      } else {
        checkStatusCode(error.value.data.status_code);
        this.serverErrors = error.value.data.errors;
        return error.value.data;
      }
    },
    async sendReport(report) {
      const formData = new FormData();
      const authStore = useAuthStore();
      formData.append('report', report);
      this.loading = true;
      const { error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/sessions/${
          this.currentSessionId
        }/session-report`,
        {
          method: 'POST',
          headers: authStore.auth.headers,
          body: formData,
        }
      );
      this.loading = false;
      if (!error.value) {
        return true;
      } else {
        if (error.value.data.errors) {
          this.serverErrors = error.value.data.errors;
        }
        checkStatusCode(error.value.data.status_code);

        return false;
      }
    },
    async getSessionStatus() {
      const authStore = useAuthStore();

      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/sessions/${
          this.currentSessionId
        }/get-status`,
        {
          method: 'Get',
          headers: authStore.auth.headers,
        }
      );
      if (!error.value) {
        return response.value.data.status;
      } else {
        checkStatusCode(error.value.data.status_code);
        this.serverErrors = error.value.data.errors;
        return error.value.data;
      }
    },
    async getSessionLogs() {
      const authStore = useAuthStore();
      if (this.currentSessionId) {
        const { data: response, error } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}/sessions/${
            this.currentSessionId
          }/log`,
          {
            method: 'Get',
            headers: authStore.auth.headers,
          }
        );
        if (!error.value) {
          this.records = response.value.data;
          return response.value.data;
        } else {
          checkStatusCode(error.value.data.status_code);
          this.serverErrors = error.value.data.errors;
          return error.value.data;
        }
      }
    },
    async sendLog(action) {
      const formData = new FormData();
      const authStore = useAuthStore();
      formData.append('action_type', action);
      this.loading = true;
      if (!this.currentSessionId) {
        await this.getCurrentSessionId();
      }
      if (this.currentSessionId) {
        const { error } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}/sessions/${
            this.currentSessionId
          }/log`,
          {
            method: 'POST',
            headers: authStore.auth.headers,
            body: formData,
          }
        );
        this.loading = false;
        if (!error.value) {
          await this.getSessionLogs();
          return true;
        } else {
          if (error.value.data.errors) {
            this.serverErrors = error.value.data.errors;
          }
          checkStatusCode(error.value.data.status_code);

          return false;
        }
      }
    },
  },
});
